import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/operators';
import FileSaver, { saveAs } from 'file-saver';
import { Distributor } from 'src/app/models/distributor';
import { Alerts } from 'src/app/utils/alerts';
import { Season } from 'src/app/models/season';
declare var $: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent implements OnInit, AfterViewInit {
  distributors: Distributor[] = [];
  loading: boolean = false;
  seasons: Season[] = [];

  distributionsExcel: boolean = false;
  distributionsPDF: boolean = false;
  distributionsForm: FormGroup;

  warehouseExcel: boolean = false;
  warehousePDF: boolean = false;
  warehouseForm: FormGroup;

  distributionExcel: boolean = false;
  distributionPDF: boolean = false;
  distributionForm: FormGroup;

  distributorDExcel: boolean = false;
  distributorDPDF: boolean = false;
  distributorDForm: FormGroup;

  returnsExcel: boolean = false;
  returnsPDF: boolean = false;
  returnsForm: FormGroup;

  accountExcel: boolean = false;
  accountForm: FormGroup;

  boxesExcel: boolean = false;
  boxesForm: FormGroup;

  itemsExcel: boolean = false;
  itemsForm: FormGroup;

  distributorsPaymentsExcel: boolean = false;
  distributorsPaysForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private api: ApiService) { }

  ngOnInit(): void {
    this.distributionsForm = this.formBuilder.group({
      dInitialDate: [null, Validators.required],
      dFinalDate: [null, Validators.required]
    });

    this.warehouseForm = this.formBuilder.group({
      wInitialDate: [null, Validators.required],
      wFinalDate: [null, Validators.required]
    });

    this.distributionForm = this.formBuilder.group({
      distributionCode: [null, Validators.required]
    });

    this.distributorDForm = this.formBuilder.group({
      ddDistributor: [null, Validators.required],
      ddInitialDate: [null, Validators.required],
      ddFinalDate: [null, Validators.required]
    });

    this.returnsForm = this.formBuilder.group({
      rDistributor: [null, Validators.required],
      rInitialDate: [null, Validators.required],
      rFinalDate: [null, Validators.required]
    });

    this.accountForm = this.formBuilder.group({
      aDistributor: [null, Validators.required],
      aSeason: [null, Validators.required]
    });

    this.boxesForm = this.formBuilder.group({
      bSeason: [null, Validators.required]
    });

    this.itemsForm = this.formBuilder.group({
      itInitialDate: [null, Validators.required],
      itFinalDate: [null, Validators.required]
    });

    this.distributorsPaysForm = this.formBuilder.group({
      dpInitialDate: [null, Validators.required],
      dpFinalDate: [null, Validators.required]
    });
  }

  ngAfterViewInit(): void {
    $('.datepicker').datepicker({ enableOnReadonly: false, todayHighlight: true, autoclose: true, language: 'es' });

    $('.select2-control').select2({ width: '100%', theme: 'bootstrap' });

    this.api.getAll('distributors/getAll').subscribe((distributors: Distributor[]) => {
      this.distributors = distributors;

      $('#distributionSelect,#returnSelect,#accountSelect').val(null).trigger('change');
    });

    this.api.getAll('seasons/getSeasons').subscribe((seasons: Season[]) => {
      this.seasons = seasons;

      $('#seasonSelect,#boxesSelect').val(null).trigger('change');
    });

    // Distributions Datepickers & Inputs
    $('#dInitialDatePicker').on('changeDate', function () {
      $('#dInitialDateInput').val($('#dInitialDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#dInitialDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#dFinalDatePicker').datepicker('setStartDate', dtFormatted);
    });

    $('#dFinalDatePicker').on('changeDate', function () {
      $('#dFinalDateInput').val($('#dFinalDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#dFinalDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#dInitialDatePicker').datepicker('setEndDate', dtFormatted);
    });

    $('#dInitialDateInput').on('change', () => {
      this.distributionsForm.controls['dInitialDate'].setValue($('#dInitialDateInput').val());
    });

    $('#dFinalDateInput').on('change', () => {
      this.distributionsForm.controls['dFinalDate'].setValue($('#dFinalDateInput').val());
    });

    // Warehouse Datepickers & Inputs
    $('#wInitialDatePicker').on('changeDate', function () {
      $('#wInitialDateInput').val($('#wInitialDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#wInitialDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#wFinalDatePicker').datepicker('setStartDate', dtFormatted);
    });

    $('#wFinalDatePicker').on('changeDate', function () {
      $('#wFinalDateInput').val($('#wFinalDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#wFinalDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#wInitialDatePicker').datepicker('setEndDate', dtFormatted);
    });

    $('#wInitialDateInput').on('change', () => {
      this.warehouseForm.controls['wInitialDate'].setValue($('#wInitialDateInput').val());
    });

    $('#wFinalDateInput').on('change', () => {
      this.warehouseForm.controls['wFinalDate'].setValue($('#wFinalDateInput').val());
    });

    // Distributor D Datepickers, Inputs & Select
    $('#ddInitialDatePicker').on('changeDate', function () {
      $('#ddInitialDateInput').val($('#ddInitialDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#ddInitialDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#ddFinalDatePicker').datepicker('setStartDate', dtFormatted);
    });

    $('#ddFinalDatePicker').on('changeDate', function () {
      $('#ddFinalDateInput').val($('#ddFinalDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#ddFinalDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#ddInitialDatePicker').datepicker('setEndDate', dtFormatted);
    });

    $('#ddInitialDateInput').on('change', () => {
      this.distributorDForm.controls['ddInitialDate'].setValue($('#ddInitialDateInput').val());
    });

    $('#ddFinalDateInput').on('change', () => {
      this.distributorDForm.controls['ddFinalDate'].setValue($('#ddFinalDateInput').val());
    });

    $('#distributionSelect').on('change', () => {
      this.distributorDForm.controls['ddDistributor'].setValue($('#distributionSelect').val());
    });

    // Returns Datepickers, Inputs & Select
    $('#rInitialDatePicker').on('changeDate', function () {
      $('#rInitialDateInput').val($('#rInitialDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#rInitialDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#rFinalDatePicker').datepicker('setStartDate', dtFormatted);
    });

    $('#rFinalDatePicker').on('changeDate', function () {
      $('#rFinalDateInput').val($('#rFinalDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#rFinalDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#rInitialDatePicker').datepicker('setEndDate', dtFormatted);
    });

    $('#rInitialDateInput').on('change', () => {
      this.returnsForm.controls['rInitialDate'].setValue($('#rInitialDateInput').val());
    });

    $('#rFinalDateInput').on('change', () => {
      this.returnsForm.controls['rFinalDate'].setValue($('#rFinalDateInput').val());
    });

    $('#returnSelect').on('change', () => {
      this.returnsForm.controls['rDistributor'].setValue($('#returnSelect').val());
    });

    // Account Selects
    $('#accountSelect').on('change', () => {
      this.accountForm.controls['aDistributor'].setValue($('#accountSelect').val());
    });

    $('#seasonSelect').on('change', () => {
      this.accountForm.controls['aSeason'].setValue($('#seasonSelect').val());
    });

    // Boxes Select
    $('#boxesSelect').on('change', () => {
      this.boxesForm.controls['bSeason'].setValue($('#boxesSelect').val());
    });

    // Items Datepickers & Inputs
    $('#itInitialDatePicker').on('changeDate', function () {
      $('#itInitialDateInput').val($('#itInitialDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#itInitialDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#itFinalDatePicker').datepicker('setStartDate', dtFormatted);
    });

    $('#itFinalDatePicker').on('changeDate', function () {
      $('#itFinalDateInput').val($('#itFinalDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#itFinalDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#itInitialDatePicker').datepicker('setEndDate', dtFormatted);
    });

    $('#dpInitialDatePicker').on('changeDate', function () {
      $('#dpInitialDateInput').val($('#dpInitialDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#dpInitialDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#dpFinalDatePicker').datepicker('setStartDate', dtFormatted);
    });

    $('#dpFinalDatePicker').on('changeDate', function () {
      $('#dpFinalDateInput').val($('#dpFinalDatePicker').datepicker('getFormattedDate'));
      var dtMax = new Date($('#dpFinalDateInput').val());
      var d = dtMax.getDate();
      var m = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = m + '/' + d + '/' + y;
      $('#dpInitialDatePicker').datepicker('setEndDate', dtFormatted);
    });

    $('#itInitialDateInput').on('change', () => {
      this.itemsForm.controls['itInitialDate'].setValue($('#itInitialDateInput').val());
    });

    $('#itFinalDateInput').on('change', () => {
      this.itemsForm.controls['itFinalDate'].setValue($('#itFinalDateInput').val());
    });

    $('#dpInitialDateInput').on('change', () => {
      this.distributorsPaysForm.controls['dpInitialDate'].setValue($('#dpInitialDateInput').val());
    });

    $('#dpFinalDateInput').on('change', () => {
      this.distributorsPaysForm.controls['dpFinalDate'].setValue($('#dpFinalDateInput').val());
    });
  }

  getDistributorData(id: string): Distributor {
    return this.distributors.find(distributor => distributor.id == id);
  }

  get isDistributionsFormValid(): boolean {
    if (this.distributionsForm.controls['dInitialDate'].value && this.distributionsForm.controls['dFinalDate'].value)
      return true;
    return false;
  }

  get isWarehouseFormValid(): boolean {
    if (this.warehouseForm.controls['wInitialDate'].value && this.warehouseForm.controls['wFinalDate'].value)
      return true;
    return false;
  }

  get isDistributionFormValid(): boolean {
    if (this.distributionForm.controls['distributionCode'].value)
      return true;
    return false;
  }

  get isDistributorDFormValid(): boolean {
    if (this.distributorDForm.controls['ddDistributor'].value && this.distributorDForm.controls['ddInitialDate'].value && this.distributorDForm.controls['ddFinalDate'].value)
      return true;
    return false;
  }

  get isReturnsFormValid(): boolean {
    if (this.returnsForm.controls['rDistributor'].value && this.returnsForm.controls['rInitialDate'].value && this.returnsForm.controls['rFinalDate'].value)
      return true;
    return false;
  }

  get isAccountFormValid(): boolean {
    if (this.accountForm.controls['aDistributor'].value && this.accountForm.controls['aSeason'].value)
      return true;
    return false;
  }

  get isBoxesFormValid(): boolean {
    if (this.boxesForm.controls['bSeason'].value)
      return true;
    return false;
  }

  get isItemsFormValid(): boolean {
    if (this.itemsForm.controls['itInitialDate'].value && this.itemsForm.controls['itFinalDate'].value)
      return true;
    return false;
  }

  get isDistributorsPaysFormValid(): boolean {
    if (this.distributorsPaysForm.controls['dpInitialDate'].value && this.distributorsPaysForm.controls['dpFinalDate'].value)
      return true;
    return false;
  }

  /* <--- Distributions Reports ---> */
  getDistributionsReportAsPDF(): void {
    this.loading = true;
    this.distributionsPDF = true;
    this.api.getAll('distributions/generateDistributionsReportAsExcel', { Initial: this.distributionsForm.controls['dInitialDate'].value, Final: this.distributionsForm.controls['dFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.distributionsPDF = false;
    })).subscribe((data: any) => {
      if (data.Warning) {
        Alerts.AlertWarning(data.Warning);
        return;
      }
      const mediaType = 'application/pdf';
      var binary = atob(data.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, 'salidas.pdf');
      this.distributionsForm.reset();
      $('#dInitialDatePicker').data('datepicker').setDate(null);
      $('#dFinalDatePicker').data('datepicker').setDate(null);
    });
  }

  getDistributionsReportAsExcel(): void {
    this.loading = true;
    this.distributionsExcel = true;
    this.api.getExcelFile('reports/getDistributionsReportAsExcel', { Initial: this.distributionsForm.controls['dInitialDate'].value, Final: this.distributionsForm.controls['dFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.distributionsExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'salidas.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.distributionsForm.reset();
      $('#dInitialDatePicker').data('datepicker').setDate(null);
      $('#dFinalDatePicker').data('datepicker').setDate(null);
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Warehouse Reports ---> */
  getWarehouseReportAsPDF(): void {
    this.loading = true;
    this.warehousePDF = true;
    this.api.getAll('reports/getWarehouseReportAsPDF', { Initial: this.warehouseForm.controls['wInitialDate'].value, Final: this.warehouseForm.controls['wFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.warehousePDF = false;
    })).subscribe((data: any) => {
      if (data.Warning) {
        Alerts.AlertWarning(data.Warning);
        return;
      }
      const mediaType = 'application/pdf';
      var binary = atob(data.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, 'reporte-almacen.pdf');
      this.warehouseForm.reset();
      $('#wInitialDatePicker').data('datepicker').setDate(null);
      $('#wFinalDatePicker').data('datepicker').setDate(null);
    }, (error: any) => {
      if (error.error) {
        Alerts.AlertFailed(error.error.message);
      } else {
        Alerts.AlertFailed(error.message);
      }
    });
  }

  getWarehouseReportAsExcel(): void {
    this.loading = true;
    this.warehouseExcel = true;
    this.api.getExcelFile('reports/getWarehouseReportAsExcel', { Initial: this.warehouseForm.controls['wInitialDate'].value, Final: this.warehouseForm.controls['wFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.warehouseExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'reporte-almacen.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.warehouseForm.reset();
      $('#wInitialDatePicker').data('datepicker').setDate(null);
      $('#wFinalDatePicker').data('datepicker').setDate(null);
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Distribution Reports ---> */
  getDistributionReportAsPDF(): void {
    this.loading = true;
    this.distributionPDF = true;
    this.api.get('distributions/generateReportParticularDistribution', this.distributionForm.controls['distributionCode'].value).pipe(finalize(() => {
      this.loading = false;
      this.distributionPDF = false;
      this.distributionForm.reset();
    })).subscribe((pdf: any) => {
      const mediaType = 'application/pdf';
      var binary = atob(pdf.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, 'salida-' + this.distributionForm.controls['distributionCode'].value + '.pdf');
    }, (error) => {
      Alerts.AlertFailed('El código de salida ingresado no existe');
      return;
    });
  }

  getDistributionReportAsExcel(): void {
    this.loading = true;
    this.distributionExcel = true;
    var distribution = this.distributionForm.controls['distributionCode'].value;
    this.api.getExcelFile('distributions/generateParticularDistributionReportAsExcel', { id: distribution }).pipe(finalize(() => {
      this.loading = false;
      this.distributionExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'salida-' + distribution + '.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.distributionForm.reset();
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Distributions by Distributor Reports ---> */
  getDistributorDReportAsPDF(): void {
    this.loading = true;
    this.distributorDPDF = true;
    this.api.getAll('reports/getDistributorDReportAsPDF', { Distributor: this.distributorDForm.controls['ddDistributor'].value, Initial: this.distributorDForm.controls['ddInitialDate'].value, Final: this.distributorDForm.controls['ddFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.distributorDPDF = false;
    })).subscribe((data: any) => {
      if (data.Error) {
        Alerts.AlertFailed(data.Error);
        return;
      }
      if (data.Warning) {
        Alerts.AlertWarning(data.Warning);
        return;
      }
      const mediaType = 'application/pdf';
      var binary = atob(data.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, 'salidas-distribuidor.pdf');
      this.distributorDForm.reset();
      $('#distributionSelect').val(null).trigger('change');
      $('#ddInitialDatePicker').data('datepicker').setDate(null);
      $('#ddFinalDatePicker').data('datepicker').setDate(null);
    });
  }

  getDistributorDReportAsExcel(): void {
    this.loading = true;
    this.distributorDExcel = true;
    var distributor = this.getDistributorData(this.distributorDForm.controls['ddDistributor'].value);
    this.api.getExcelFile('reports/getDistributorDReportAsExcel', { Distributor: distributor.id, Initial: this.distributorDForm.controls['ddInitialDate'].value, Final: this.distributorDForm.controls['ddFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.distributorDExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'salidas-distribuidor-' + distributor.code.toLowerCase() + '.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.distributorDForm.reset();
      $('#distributionSelect').val(null).trigger('change');
      $('#ddInitialDatePicker').data('datepicker').setDate(null);
      $('#ddFinalDatePicker').data('datepicker').setDate(null);
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Returns Report ---> */
  getReturnsReportAsPDF(): void {
    this.loading = true;
    this.returnsPDF = true;
    this.api.getAll('reports/getReturnsReportAsPDF', { Distributor: this.returnsForm.controls['rDistributor'].value, Initial: this.returnsForm.controls['rInitialDate'].value, Final: this.returnsForm.controls['rFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.returnsPDF = false;
    })).subscribe((data: any) => {
      if (data.Warning) {
        Alerts.AlertWarning(data.Warning);
        return;
      }
      const mediaType = 'application/pdf';
      var binary = atob(data.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, 'reporte-devoluciones.pdf');
      this.returnsForm.reset();
      $('#returnSelect').val(null).trigger('change');
      $('#rInitialDatePicker').data('datepicker').setDate(null);
      $('#rFinalDatePicker').data('datepicker').setDate(null);
    });
  }

  getReturnsReportAsExcel(): void {
    this.loading = true;
    this.returnsExcel = true;
    var distributor = this.getDistributorData(this.returnsForm.controls['rDistributor'].value);
    this.api.getExcelFile('reports/getReturnsReportAsExcel', { Distributor: distributor.id, Initial: this.returnsForm.controls['rInitialDate'].value, Final: this.returnsForm.controls['rFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.returnsExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'reporte-devoluciones-' + distributor.code.toLowerCase()  + '.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.returnsForm.reset();
      $('#returnSelect').val(null).trigger('change');
      $('#rInitialDatePicker').data('datepicker').setDate(null);
      $('#rFinalDatePicker').data('datepicker').setDate(null);
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Account Report ---> */
  getAccountReport(): void {
    this.loading = true;
    this.accountExcel = true;
    var distributor = this.getDistributorData(this.accountForm.controls['aDistributor'].value);
    this.api.getExcelFile('account/getAccountStatus', { Distributor: distributor.id, Season: this.accountForm.controls['aSeason'].value }).pipe(finalize(() => {
      this.loading = false;
      this.accountExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'estado-cuenta-' + distributor.code.toLowerCase() + '.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      }
      this.accountForm.reset();
      $('#seasonSelect').val(null).trigger('change');
      $('#accountSelect').val(null).trigger('change');
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Boxes Report ---> */
  getBoxesReport(): void {
    this.loading = true;
    this.boxesExcel = true;
    var season = this.boxesForm.controls['bSeason'].value;
    this.api.getExcelFile('boxes/getBoxesReport', { Season: season }).pipe(finalize(() => {
      this.loading = false;
      this.boxesExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'reporte-cajas-' + season + '.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.boxesForm.reset();
      $('#boxesSelect').val(null).trigger('change');
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Items Report ---> */
  getItemsReport(): void {
    this.loading = true;
    this.itemsExcel = true;
    this.api.getExcelFile('reports/getItemsReportAsExcel', { startDate: this.itemsForm.controls['itInitialDate'].value, finalDate: this.itemsForm.controls['itFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.itemsExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], 'articulos-pedidos.xlsx', { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.itemsForm.reset();
      $('#itInitialDatePicker').data('datepicker').setDate(null);
      $('#itFinalDatePicker').data('datepicker').setDate(null);
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  /* <--- Payments Report ---> */
  getPaymentsReport(): void {
    this.loading = true;
    this.distributorsPaymentsExcel = true;
    
    let initialDateString = this.distributorsPaysForm.controls['dpInitialDate'].value;
    let finalDateString = this.distributorsPaysForm.controls['dpFinalDate'].value;

    let initialDate = new Date(initialDateString);
    let finalDate = new Date(finalDateString);

    let day = ('0' + initialDate.getDate()).slice(-2); // Asegura que tenga 2 dígitos
    let month = ('0' + (initialDate.getMonth() + 1)).slice(-2); // Meses indexados desde 0
    let year = initialDate.getFullYear();
    let formattedStartDate = `${day}_${month}_${year}`;
    
    let dayF = ('0' + finalDate.getDate()).slice(-2); // Asegura que tenga 2 dígitos
    let monthF = ('0' + (finalDate.getMonth() + 1)).slice(-2); // Meses indexados desde 0
    let yearF = finalDate.getFullYear();
    let formattedFinalDate = `${dayF}_${monthF}_${yearF}`;

    this.api.getExcelFile('payments/getDistributorsPaymentsAsExcel', { startDate: this.distributorsPaysForm.controls['dpInitialDate'].value, finalDate: this.distributorsPaysForm.controls['dpFinalDate'].value }).pipe(finalize(() => {
      this.loading = false;
      this.distributorsPaymentsExcel = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.ms-excel' });
      if (blob.size > 0) {
        var file = new File([blob], "reporte_pago_de_distribuidores-"+formattedStartDate+"-a-"+formattedFinalDate+".xlsx", { type: 'application/vnd.ms-excel' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.distributorsPaysForm.reset();
      $('#dpInitialDatePicker').data('datepicker').setDate(null);
      $('#dpFinalDatePicker').data('datepicker').setDate(null);
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }
}